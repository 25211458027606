<template>
  <div>
    <div class="row">
      <div class="col col-2">
        <label for="">Environment:</label>
        <dropdown-component
          v-if="devs.length > 0"
          :key="devs"
          :options="devs"
          :value="'env'"
          :item="'env'"
          :selected="seletedEnv"
          @change="onChangeEnv"
        />
      </div>
      <div class="col col-3">
        <label for="">Branch:</label>
        <input
          v-model="selectedBranch"
          class="form-control"
          placeholder="Branch Name"
          type="text"
        />
      </div>
      <div class="col col-2">
        <label for="">Version:</label
        ><input
          v-model="version"
          class="form-control"
          placeholder="Version"
          type="text"
        />
      </div>
      <div class="col col-5">
        <label for="">Description:</label
        ><input
          v-model="description"
          class="form-control"
          placeholder="Description"
          type="textarea"
        />
      </div>
      <div class="col col-2">
        <br />
        <a-popover placement="right">
          <template #content v-if="seletedEnv == 'retrocausal'">
            <p>
              Environment disabled. Use the deployment script to deploy to
              production
            </p>
          </template>
          <a-button
            class="btn btn-primary mt-1"
            type="primary"
            :disabled="isDeploying || seletedEnv == 'retrocausal'"
            @click="createDeployment"
          >
            Start Update
          </a-button>
        </a-popover>
      </div>
    </div>
    <div v-if="latestDeployment.show" class="row mt-5 of-h">
      <div class="col col-12">
        <h4>Latest Deployment:</h4>
      </div>
      <hr />
      <div class="col col-4">
        <h5>Environment</h5>
        <h6 class="text-secondary">
          {{ latestDeployment.env }}
        </h6>
      </div>
      <div class="col col-4">
        <h5>Version</h5>
        <h6 class="text-secondary">
          {{ latestDeployment.version }}
        </h6>
      </div>
      <div class="col col-4">
        <h5>Status</h5>
        <h6 class="text-secondary">
          {{ latestDeployment.status }}
        </h6>
      </div>
      <hr />
    </div>
    <div class="row">
      <div class="mt-2">
        <!-- <h4>Pending Deployment:</h4>
        <div class="progress mt-3">
          <div class="progress-bar" :style="{ width: progress + '%' }">
            {{ progress }}%
          </div>
        </div>
      </div> -->

        <a-table :columns="columns" :data-source="pendingDeployments">
          <template #title>
            <a-typography-title :level="5">
              Pending Deployment
              <a-tag color="blue">
                {{ pendingDeployments.length }}
              </a-tag>
            </a-typography-title>
          </template>

          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'progress'">
              <a-progress :percent="record.progress" :steps="5" />
            </template>

            <template v-if="column.dataIndex === 'status'">
              <a-tag
                v-if="record.currentState"
                :color="processStateColors[record.currentState]"
              >
                {{ record.currentState }}
              </a-tag>
            </template>
            <template v-if="column.dataIndex === 'actions'">
              <div class="actions" style="child-align: left;">
                <span>
                  <!-- <span v-if="record.abondoned">
                    <Tooltip title="Delete branch">
                      <a-popconfirm
                        title="Are you sure to delete this branch?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="deleteBranch(record)"
                      >
                        <a-button type="danger">
                          <template #icon>
                            <DeleteOutlined />
                          </template>
                        </a-button>
                      </a-popconfirm>
                    </Tooltip>
                  </span> -->
                  <span>
                    <Tooltip title="Abandon branch">
                      <a-button
                        type="danger"
                        @click="abandonBranch(record.version_number)"
                      >
                        <template #icon>
                          <CloseSquareOutlined />
                        </template>
                      </a-button>
                    </Tooltip>
                  </span>
                </span>

                <!-- <span
                  v-if="
                    latestBranchId[
                      `${record.running_environment}-${record.branch}`
                    ] !== record.id &&
                      record.running_environment !== 'retrocausal' &&
                      record.running_environment !== 'retrocausal'
                  "
                >
                  N/A
                </span> -->

                <!-- <span v-if="record.branch !== 'retro_gui'">
                <Tooltip title="Delete Branch">
                  <a-button
                    style="margin-right: 8px"
                    type="danger"
                    @click="confirmDelete(record)"
                    :disabled="isDeploying"
                  >
                    <template #icon>
                      <DeleteOutlined />
                    </template>
                  </a-button>
                </Tooltip>
              </span> -->
              </div>
            </template>

            <!-- <template v-if="column.dataIndex === 'actions'">
            <div class="actions col-2">
              <span style="margin-bottom: 10px">
                <a-button
                    type="primary"
                    @click="downloadBinaries(record)"
                    :disabled="isDeploying"
                >
                  Download
                </a-button>
              </span>
              <span style="margin-top: 10px">
                <a-button
                    type="primary"
                    @click="deleteBranch(record)"
                    :disabled="isDeploying"
                >
                  Delete
                </a-button>
              </span>


              </div>

          </template> -->
          </template>
        </a-table>
      </div>
    </div>

    <div class="row mt-5 of-h">
      <div class="col col-12">
        <h4>Latest Branch</h4>
      </div>
      <hr />
      <div class="col col-3">
        <h5>Environment</h5>
        <h6 class="text-secondary">
          {{ latestRetroGuiDevBranch.env }}
        </h6>
      </div>
      <div class="col col-3">
        <h5>Version</h5>
        <h6 class="text-secondary">
          {{ latestRetroGuiDevBranch.version }}
        </h6>
      </div>
      <div class="col col-3">
        <h5>Status</h5>
        <h6 class="text-secondary">
          {{ latestRetroGuiDevBranch.status }}
        </h6>
      </div>

      <!-- <div class="col col-3">
        <h5>Actions</h5>
        <Tooltip title="Deploy To Prod">
          <a-button
            type="primary"
            @click="
              confirmMove(
                this.latestRetroGuiDevBranch.version,
                this.latestRetroGuiDevBranch.env
              )
            "
            style="margin-bottom: 5px"
          >
            <template #icon
              ><i class="bi bi-arrow-up-right mr-1 mb-1"></i
            ></template>
          </a-button>
        </Tooltip>
      </div> -->

      <div class="col col-3">
        <h5>Actions</h5>
        <span v-if="latestRetroGuiDevBranch.env === 'retrocausal-development'">
          <Tooltip title="Deploy To Prod">
            <a-button
              type="primary"
              :disabled="latestRetroGuiDevBranch.version === ''"
              style="margin-bottom: 5px"
              @click="
                confirmMove(
                  latestRetroGuiDevBranch.version,
                  latestRetroGuiDevBranch.env
                )
              "
            >
              <template #icon
                ><i class="bi bi-arrow-up-right mr-1 mb-1"
              /></template>
            </a-button>
          </Tooltip>
        </span>
        <h6
          v-if="latestRetroGuiDevBranch.env === 'retrocausal'"
          class="text-secondary"
        >
          N/A
        </h6>
      </div>
      <hr />
    </div>

    <div>
      <a-modal
        title="Are you sure you want to delete?"
        :visible="isDeletePopupVisible"
        ok-text="Yes"
        cancel-text="No"
        @ok="deleteBranch"
        @cancel="cancelDeleteBranch"
      />
    </div>

    <div>
      <a-modal
        title="Are you sure you want the binary move to production?"
        :visible="isMoveBinaryToProdPopupVisible"
        ok-text="Yes"
        cancel-text="No"
        @ok="moveBranch"
        @cancel="cancelMoveBranch"
      />
    </div>

    <div class="col mt-5">
      <a-table
        :columns="columns"
        :data-source="releases"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #title>
          <a-typography-title :level="5">
            Previous Releases
            <a-tag color="blue">
              {{ releases.length }}
            </a-tag>
          </a-typography-title>
        </template>

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'progress'">
            <a-progress :percent="record.progress" :steps="5" />
          </template>

          <template v-if="column.dataIndex === 'status'">
            <a-tag
              v-if="record.currentState"
              :color="processStateColors[record.currentState]"
            >
              {{ record.currentState }}
            </a-tag>
          </template>
          <template v-if="column.dataIndex === 'actions'">
            <div class="actions" style="child-align: left;">
              <span
                v-if="
                  (latestBranchId[
                    `${record.running_environment}-${record.branch}`
                  ] === record.id &&
                    record.running_environment !== 'retrocausal') ||
                    record.running_environment === 'retrocausal'
                "
              >
                <Tooltip title="Download Binaries">
                  <a-button
                    style="margin-right: 8px"
                    type="primary"
                    @click="downloadBinaries(record)"
                  >
                    <template #icon>
                      <DownloadOutlined />
                    </template>
                  </a-button>
                </Tooltip>
                <Tooltip title="Abandon branch">
                  <a-button
                    v-if="
                      record.branch != 'retro_gui' &&
                        record.currentState != 'ABANDONED'
                    "
                    type="danger"
                    @click="abandonBranch(record.version_number)"
                  >
                    <template #icon>
                      <CloseSquareOutlined />
                    </template>
                  </a-button>
                </Tooltip>
                <Tooltip title="Delete branch">
                  <a-popconfirm
                    title="Are you sure to delete this branch?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="deleteBranch(record)"
                  >
                    <a-button
                      v-if="
                        record.branch != 'retro_gui' &&
                          record.currentState == 'ABANDONED'
                      "
                      type="danger"
                    >
                      <template #icon>
                        <DeleteOutlined />
                      </template>
                    </a-button>
                  </a-popconfirm>
                </Tooltip>
              </span>

              <span
                v-if="
                  latestBranchId[
                    `${record.running_environment}-${record.branch}`
                  ] !== record.id &&
                    record.running_environment !== 'retrocausal' &&
                    record.running_environment !== 'retrocausal'
                "
              >
                N/A
              </span>

              <!-- <span v-if="record.branch !== 'retro_gui'">
                <Tooltip title="Delete Branch">
                  <a-button
                    style="margin-right: 8px"
                    type="danger"
                    @click="confirmDelete(record)"
                    :disabled="isDeploying"
                  >
                    <template #icon>
                      <DeleteOutlined />
                    </template>
                  </a-button>
                </Tooltip>
              </span> -->
            </div>
          </template>

          <!-- <template v-if="column.dataIndex === 'actions'">
            <div class="actions col-2">
              <span style="margin-bottom: 10px">
                <a-button
                    type="primary"
                    @click="downloadBinaries(record)"
                    :disabled="isDeploying"
                >
                  Download
                </a-button>
              </span>
              <span style="margin-top: 10px">
                <a-button
                    type="primary"
                    @click="deleteBranch(record)"
                    :disabled="isDeploying"
                >
                  Delete
                </a-button>
              </span>


              </div>

          </template> -->
        </template>
      </a-table>
      <!--      <table id="table" class="table table-borderless" style="color: white;">-->
      <!--        <thead class="thead-dark table-header-sticky">-->
      <!--        <tr>-->
      <!--          <th v-for="header in tableHeaders" :key="header" scope="col">-->
      <!--            {{ header }}-->
      <!--          </th>-->
      <!--        </tr>-->
      <!--        </thead>-->
      <!--        <tbody v-if="releases.length > 0">-->
      <!--        <tr v-for="release in releases" :key="release.id">-->
      <!--          <td class="text-secondary">{{ release.running_environment }}</td>-->
      <!--          <td class="text-secondary">{{ release.branch }}</td>-->
      <!--          <td class="text-secondary">{{ release.version_number }}</td>-->
      <!--          <td class="text-secondary">{{ release.description }}</td>-->
      <!--          <td class="text-secondary">-->
      <!--            &lt;!&ndash; {{ release.progress }} &ndash;&gt;-->
      <!--            <div class="progress">-->
      <!--              <div-->
      <!--                  class="progress-bar"-->
      <!--                  :style="{ width: release.progress + '%' }"-->
      <!--              >-->
      <!--                {{ release.progress }}%-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </td>-->
      <!--          <td class="text-secondary">-->
      <!--              <span-->
      <!--                  class="badge badge-pill"-->
      <!--                  :class="getBadgeClasses(release.currentState)"-->
      <!--              >{{ release.currentState }}</span-->
      <!--              >-->
      <!--          </td>-->
      <!--          <td class="text-secondary">{{ release.start_time }}</td>-->
      <!--          <td class="text-secondary">{{ release.end_time }}</td>-->
      <!--          <td>-->
      <!--            <div class="col col-4">-->
      <!--              <button-->
      <!--                  class="btn btn-primary mt-1"-->
      <!--                  @click="downloadBinaries"-->
      <!--                  :disabled="isDeploying"-->
      <!--              >-->
      <!--                Download-->
      <!--              </button>-->
      <!--              <button-->
      <!--                  class="btn btn-primary mt-1"-->
      <!--                  @click="deleteBranch"-->
      <!--                  :disabled="isDeploying"-->
      <!--              >-->
      <!--                Delete-->
      <!--              </button>-->

      <!--            </div>-->
      <!--          </td>-->
      <!--        </tr>-->
      <!--        </tbody>-->
      <!--      </table>-->
      <!--      <div class="row row-cols-6">-->
      <!--        <br/>-->
      <!--        <button-->
      <!--            class="btn btn-primary mt-1"-->
      <!--            @click="previousPage"-->
      <!--            :disabled="!this.previousLink"-->
      <!--        >-->
      <!--          Previous-->
      <!--        </button>-->
      <!--        <button-->
      <!--            class="btn btn-primary mt-1"-->
      <!--            @click="nextPage"-->
      <!--            :disabled="!this.nextLink"-->
      <!--        >-->
      <!--          Next-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import DropdownComponent from '../../../shared/Components/Dropdown.vue';
import httpClient from '../../../../service/httpClient.js';
import dateHelper from '../../../shared/Helpers/dateHelper';
import { useToast } from 'vue-toastification';
import ApiHelper from '@/services';
import {
  DownloadOutlined,
  DeleteOutlined,
  CloseSquareOutlined
} from '@ant-design/icons-vue';
import { mapActions, mapGetters } from 'vuex';
import fcm from 'src/services/fcm';
import BinaryDeploymentService from 'src/services/binaryDevelopments';

export default {
  components: {
    DropdownComponent,
    DownloadOutlined,
    DeleteOutlined,
    CloseSquareOutlined
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data: function() {
    return {
      devs: [
        { id: 1, env: 'DEVELOPMENT' },
        { id: 2, env: 'PRODUCTION' }
      ],
      seletedEnv: null,
      defaultBranch: 'retro_gui',
      selectedBranch: null,
      version: '',
      description: '',
      tableHeaders: [
        'Environment',
        'Branch',
        'Version',
        'Description',
        'Progress (%)',
        'Status',
        'Start Time',
        'End Time',
        'Actions'
      ],
      columns: [
        {
          title: 'Environment',
          dataIndex: 'running_environment'
        },
        {
          title: 'Branch',
          dataIndex: 'branch'
        },
        {
          title: 'Version',
          dataIndex: 'version_number'
        },
        {
          title: 'Description',
          dataIndex: 'description'
        },
        {
          title: 'Progress (%)',
          dataIndex: 'progress',
          width: 120
        },
        {
          title: 'Status',
          dataIndex: 'status'
        },
        {
          title: 'Actions',
          dataIndex: 'actions',
          width: 150
        }
      ],
      progress: 10,
      isDeploying: true,
      polling: null,
      branches: [],
      BranchToMove: {},
      recordToDelete: null,
      isDeletePopupVisible: false,
      isMoveBinaryToProdPopupVisible: false,
      pendingDeployments: [],
      processStateColors: {
        FINISHED: 'success',
        PENDING: 'processing',
        FAILED: 'error',
        RUNNING: 'processing',
        ABANDONED: 'error'
      }
    };
  },

  computed: {
    ...mapGetters([
      'releases',
      'isLoading',
      'latestBranchId',
      'latestDate',
      'latestDeployment',
      'currentPage',
      'totalResult',
      'latestRetroGuiDevBranch'
    ]),
    pagination() {
      return {
        total: this.totalResult,
        current: this.currentPage,
        showSizeChanger: false,
        position: ['bottomCenter']
      };
    }
  },

  mounted() {
    this.fetchEnvironments();
    this.getPreviousReleases();
    this.getPendingDeployement();
    this.getLatestDeployableBranch();
  },

  beforeUnmount() {
    clearTimeout(this.polling);
  },

  methods: {
    ...mapActions([
      'getPreviousReleases',
      'downloadBinaries',
      'getLatestDeployment',
      'getLatestDeployableBranch',
      'deleteBranchFromAWS',
      'moveBranchToDev',
      'setCurrentPage'
    ]),
    getFormattedDate() {
      const date = new Date();

      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(date.getDate()).padStart(2, '0')} ${String(
        date.getHours()
      ).padStart(2, '0')}:${String(date.getMinutes()).padStart(
        2,
        '0'
      )}:${String(date.getSeconds()).padStart(2, '0')}.${String(
        date.getMilliseconds()
      ).padStart(6, '0')}`;
    },
    async abandonBranch(version) {
      const formattedDate = this.getFormattedDate();
      const payload = {
        abondoned: true,
        currentState: 'ABANDONED',
        end_time: formattedDate
      };
      const [error, data] = await BinaryDeploymentService.abandonBranch(
        version,
        payload
      );
      if (error) {
        this.toast.error('Error while branch abandoning.');
        return;
      }
      this.toast.success('Branch anandoned successfully.');
    },
    getBadgeClasses(value) {
      return {
        'badge-success': value === 'FINISHED',
        'badge-info': value === 'PENDING',
        'badge-danger': value === 'FAILED',
        'badge-primary': value === 'RUNNING'
      };
    },

    onChangeEnv(value) {
      console.log('env', value);
      this.latestDeployment.show = false;
      this.seletedEnv = value;
      this.getLatestDeployment(value);
      if (this.seletedEnv !== 'retrocausal-development') {
        this.selectedBranch = this.defaultBranch;
      }
    },

    async fetchEnvironments() {
      const res = await httpClient.get(
        'binary_deployment/running_environments/'
      );
      if (res === 'error') console.log('error');
      console.log('response', res);
      this.devs = res.map(el => ({ id: el[0], env: el[1] }));
    },

    async createDeployment() {
      if (!this.seletedEnv) {
        this.toast.info('Please Select the Environment First!');
        return;
      }

      try {
        await httpClient.post(
          'binary_deployment/',
          {
            version_number: this.version,
            description: this.description,
            branch: this.selectedBranch,
            running_environment: this.seletedEnv
          },
          true,
          false,
          true,
          'Starting to generate Update',
          'Failed to start update'
        );
        this.getPendingDeployement();
      } catch (e) {
        console.log(e);
      }
      this.seletedEnv = null;
      this.version = '';
      this.description = '';
      this.selectedBranch = null;
    },

    cancelDeleteBranch() {
      this.isDeletePopupVisible = false;
    },

    cancelMoveBranch() {
      this.isMoveBinaryToProdPopupVisible = false;
    },

    confirmDelete(record) {
      this.recordToDelete = record;
      this.isDeletePopupVisible = true;
    },

    confirmMove(version, env) {
      this.BranchToMove.version = version;
      this.BranchToMove.env = env;

      this.isMoveBinaryToProdPopupVisible = true;
    },

    async deleteBranch(record) {
      const delete_record = {
        running_environment: record.running_environment,
        branch: record.branch,
        id: record.id
      };
      await this.deleteBranchFromAWS(delete_record);
    },

    async moveBranch() {
      await this.moveBranchToDev({
        version: this.BranchToMove.version,
        env: this.BranchToMove.env
      });

      this.BranchToMove.version = null;
      this.BranchToMove.env = null;
      this.isMoveBinaryToProdPopupVisible = false;
    },

    handleTableChange(pag) {
      const { current } = pag;
      this.setCurrentPage(current);
    },

    async getPendingDeployement() {
      console.log('getPendingDeployement');
      const res = await httpClient.get(
        'binary_deployment/pending_deployments/',
        false
      );

      if (res === 'error') {
        this.toast.error('Error Occured!');
        return;
      }

      if (res.length > 0) {
        this.pendingDeployments = res;
        if (this.isDelayed(res[0])) {
          console.log('delayed');
        }

        this.isDeploying = true;
        this.progress = res[0].progress;
        this.polling = setTimeout(this.getPendingDeployement, 5000);
      } else {
        this.isDeploying = false;
        this.progress = 0;
        this.getPreviousReleases();
        clearTimeout(this.polling);
      }
    },
    isDelayed(release) {
      const { start_time } = release;
      const difference = dateHelper.dateDifference(
        new Date(),
        start_time,
        'hour',
        true
      );

      return difference > 3;
    }
  }
};
</script>
<style scoped>
.of-h {
  overflow: hidden;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>
